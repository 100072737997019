/* write your css here */
*{
    overflow-x: hid;
}
.animation{
    display: inline-block;
    margin: 0 0.5rem;
  
    animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 3s; /* don't forget to set a duration! */
  
}
@media (max-width: 768px) {
    .home-heading-text {
        margin-top: 75%;
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1024px;}
}
@media (min-width: 0px) and (max-width: 1024px) {
    .cities-svg-wrapper {
        display: flex
;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 21px;
        padding-right: 21px;
        cursor: pointer;
        padding-top: 13px;
    }
}

@media (max-width: 767px) {
    .cities-svg-wrapper {
        display: flex
;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 21px;
        padding-right: 21px;
        cursor: pointer;
        padding-top: 13px;
    }
}

.imp{
    border: 2px solid #000000;
    background-color: #00ad64;
}



@media (max-width: 767px) {
    .overlap {
        margin-top: 30%;
    }
}
@media screen and (min-width: 764px) and (max-width: 1024px) {
    .s{
        scale: 70%;
    }
  }

.campus-facilities-container{
    scale: 80%;
}

@media (max-width: 1024px) {

    .campus-facilities-container{
        scale: 60%;
    }
    
  }

.our-prgrams-id__batch-sign-up-card{
    background-color: #00ad64;
}
@media (max-width: 1024px) {
    .as{
        display: flex; 
        align-items: center;
        transform: translateX(164px);
    }
    .ass{
        height: 10%;
    }
  }
.color{
    background-color: #2C70EE;
    
}
@media (max-width: 519px) {
    .suraj{
        width:100%;
        height: 60%;
    }
  }


  @media screen and (min-width: 764px) and (max-width: 1440px) {
    .cards{
        width: 90%;
    }
  }


  .card {
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid #006400;
    border-radius: 5px;
    padding: 10px;
    max-width: 400px;
    background-color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .date-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #006400;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #006400;
  }
  
  .content h3 {
    margin: 0;
    font-size: 16px;
    color: #000;
  }
  
  .details-link {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    font-size: 14px;
    color: #006400;
    margin-top: 5px;
  }
  
  .details-link span {
    font-size: 18px;
    transform: rotate(90deg);
  }
  
  .details-link:hover {
    text-decoration: underline;
  }
  .stat-card-container-parent-div__desktop{display:grid;grid-template-columns:repeat(4,1fr);-moz-column-gap:30px;column-gap:30px;row-gap:40px}
  
  .category-card{
    position: relative;
    width: 300px; /* Adjust as needed */
    height: 200px;
    background: linear-gradient(135deg, #ffd1dc, #c1e1ff, #d4ffea);
    border-radius: 12px; /* Rounded corners */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center-align the text */
}

.category-name {
    position: relative;
    color: white; /* Text color */
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; /* Remove underline from links */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background for readability */
    padding: 10px 20px;
    border-radius: 8px; /* Smooth edges */
    display: inline-block;
}

    .category-name:hover {
        color: #ffd1dc; /* Change text color on hover */
        background: rgba(0, 0, 0, 0.7); /* Darker background on hover */
        text-decoration: none; /* Ensure underline doesn't appear on hover */
    }


    .map-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      
      .responsive-iframe {
        width: 90%; /* Default width for mid to large screens */
        max-width: 1600px; /* Ensures proper scaling up to 1900px */
        height: 500px;
        border: none;
      }
      
      /* Adjustments for extra-large screens (1900px and above) */
      @media (min-width: 1900px) {
        .responsive-iframe {
          width: 1200px; /* Max width */
        }
      }
      
      /* Ensuring full width on smaller screens */
      @media (max-width: 1200px) {
        .responsive-iframe {
          width: 100%;
        }
      }
      
/* app.css */

/* Change the arrow color to black using CSS filter */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: transparent; /* Remove background */
    border: none; /* Remove any border */
    filter: invert(0) sepia(1) saturate(5) hue-rotate(180deg) brightness(0) contrast(0); /* Modify to black */
}

.carousel-control-prev-icon {
    filter: invert(1) brightness(0) contrast(100%) saturate(1000%) sepia(0%) hue-rotate(180deg);
}

.carousel-control-next-icon {
    filter: invert(1) brightness(0) contrast(100%) saturate(1000%) sepia(0%) hue-rotate(180deg);
}
